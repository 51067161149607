.editPortfolioModal{
    p{
        font-weight: 400;
        font-size: 12px;
        color: var(--text-secondary);
    }
    h2{
        font-weight: 400;
        font-size: 20px;
        color: var(--text-primary);
        margin: 40px 0;
    }
    .ant-form-item-label > label{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }
    .ant-upload.ant-upload-select-picture-card{
        border: 1px solid var(--border-color);
        border-radius: 50%;
        width: 64px;
        height: 64px;
        img{
            border-radius: 50%;
            height: 62px;
        }
    }
    .upload-btn{
        display: flex;
        align-items: center;
    }
    .btns{
        margin-top: 40px;
        .ant-form-item-control-input-content{
            display: flex;
            justify-content: space-between; 
        }
    }
    .input{
        padding: 16px;
        background: var(--background-secondary);
        border-radius: 8px;
        .ant-input-group-wrapper{
            .ant-input-group-addon{
                border: none;
                background: none;
            }
            .ant-input{
                border: none;
                background: none;
                text-align: right;
                font-weight: 700;
                font-size: 16px;
                color: var(--text-primary);
            }
        }
    }
}