.waveModal{
    .token{
        border: 1px solid var(--border-color);
        border-radius: 12px;
        padding: 16px;
        &>div{
            &:first-of-type{
                font-weight: 700;
                font-size: 14px;
                color: var(--text-primary);
            }
            &:last-of-type{
                font-weight: 700;
                font-size: 16px;
                color: var(--text-primary);
            }
        }
    }
    h2{
        font-weight: 500;
        font-size: 16px;
        color: var(--text-primary);
    }
    .ant-form-item-label{
        &>label{
            font-weight: 500;
            font-size: 16px;
            color: var(--text-primary);
        }
    }
    p{
        font-weight: 400;
        font-size: 12px;
        color: var(--text-secondary);
    }
    .input{
        padding: 16px;
        background: var(--background-secondary);
        border-radius: 8px;
        .ant-input-group-wrapper{
            .ant-input-group-addon{
                border: none;
                background: none;
            }
            .ant-input{
                border: none;
                background: none;
                text-align: right;
                font-weight: 700;
                font-size: 16px;
                color: var(--text-primary);
            }
        }
    }
    .notifications{
        display: flex;
        justify-content: space-between;
    }
    .footer{
        display: flex;
        justify-content: space-between;
    }
}