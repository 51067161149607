.promoteModal{
    .ant-drawer-body{
        padding: 0;
    }
    .input{
        padding: 16px;
        background: var(--background-secondary);
        border-radius: 8px;
        .ant-input-group-wrapper{
            .ant-input-group-addon{
                border: none;
                background: none;
            }
            .ant-input{
                border: none;
                background: none;
                text-align: right;
                font-weight: 700;
                font-size: 16px;
                color: var(--text-primary);
            }
        }
    }
    h2{
        font-weight: 500;
        font-size: 16px;
        color: var(--text-primary);
    }
    .ant-form-item-label{
        &>label{
            font-weight: 500;
            font-size: 16px;
            color: var(--text-primary);
        }
    }
    p{
        font-weight: 400;
        font-size: 12px;
        color: var(--text-secondary);
    }
    .rightArrow {
        transform: rotate(90deg);
        display: block;
        margin: 10px auto;
    }
    .ant-modal-body{
        padding: 0;
    }
    .step1{
        padding: 24px 24px 0; 
    }
    .step2{
        padding: 24px;
        &.approve{
            background: #F2F2F2;
            opacity: 0.4;
        }
    }
    .step3{
        background: #F2F2F2;
        color: #B9B9B9;
        padding: 24px;
        text-align: center;
        &.success{
            background: #F3FFED;
            color: #000;
            word-break: break-all;
        }
    }
    .ant-btn{
        font-weight: 700;
        height: 40px;
    }
    .ant-btn-default{
        color: #5542F6;
        border: 1px solid #5542F6;
        box-shadow: none;
        text-shadow: none;
    }
    .ant-btn-primary{
        color: #5542F6;
        background: #DEDAFF;
        border: none;
    }
    .ant-modal-footer{
        padding: 0;
    }
    .ant-input[disabled]{
        color: #000;
        background: #fff;
    }
}