.markets{
    &>div{
        padding: 0 48px;
        @media (max-width: 960px) {
            padding: 0 30px;
        }
        @media (max-width: 768px) {
            padding: 0 20px;
        }
    }
    .tokenAvator{
        width: 40px;
        height: 40px;
        background: #ddd;
        border-radius: 50%;
        margin-right: 10px;
    }
    .title{
        font-weight: 700;
        font-size: 48px;
        color: var(--text-primary);
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        @media (max-width: 768px) {
            font-size: 30px;
            flex-direction: column;
            align-items: flex-start;
        }
        &>div{
          &:last-of-type{
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
          .ant-btn{
            margin-right: 12px;
            &:last-of-type{
                margin-right: 0;
            }
          }
        }
    }
    .rink{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 24px;
        @media (max-width: 992px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
        }
        &>div{
            background: var(--rink-card-background);
            border-radius: 12px;
            padding: 20px;
            display: flex;
            align-items: center;
            font-weight: 700;
            font-size: 16px;
            color: var(--text-primary);
            cursor: pointer;
            svg{
                font-size: 30px;
                margin-right: 18px;
                width: 40px;
            }
        }
    }
    .tokens{
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 120px));
        grid-gap: 12px;
        &>div{
            border: 1px solid var(--border-color);
            border-radius: 12px;
            padding: 16px;
            display: flex;
            align-items: center;
            font-weight: 700;
            font-size: 14px;
            color: var(--text-primary);
            cursor: pointer;
            .ant-avatar {
                margin-right: 10px;
            }
        }
    }
    .marketsList,.managerList,.portfolioList{
        .tokenInfo{
            display: flex;
            font-weight: 700;
            font-size: 16px;
            color: var(--text-primary);
            align-items: center;
            &>div{
                max-width: 118px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            span{
                font-weight: 400;
                font-size: 12px;
                color: #71717A;
            }
            .ant-avatar{
                margin-right: 10px;
            }
        }
        .managerInfo{
            border-top: 1px solid var(--border-color);
            padding-top: 10px;
            margin-top: 10px;
            &>div{
                &:first-of-type{
                    font-weight: 500;
                    font-size: 12px;
                    color: var(--text-primary);
                }
                &:last-of-type{
                    font-weight: 400;
                    font-size: 12px;
                    color: var(--text-secondary);
                }
            }
        }
        .price{
            font-weight: 700;
            font-size: 20px;
            color: var(--text-primary);
            b{
                font-weight: 500;
                font-size: 12px;
            }
        }
        .ant-list-items{
            width: 100%;
            max-width: 100%;
            display: grid;
            grid-template-columns: repeat(4, minmax(220px, 1fr));
            grid-gap: 24px;
            @media (max-width: 992px) {
                grid-template-columns: repeat(2, 1fr);
            }
            @media (max-width: 768px) {
                grid-template-columns: repeat(1, 1fr);
            }
            .ant-list-item{
                border: none;
                cursor: pointer;
                padding: 0;
            }
        }
        .ant-card{
            border: 1px solid var(--border-color);
            width: 100%;
            border-radius: 12px;
            padding: 16px;
            &-head-title{
                img{ background: #ddd; border-radius: 50%;}
            }
            &-extra{
                padding: 0;
                font-size: 10px;
                display: flex;
                align-items: center;
                cursor: pointer;
                svg{
                    font-size: 15px;
                }
                .anticon-more{
                    margin-left: 10px;
                }
                b{
                    &.up{
                        color: #14B8A6;
                    }
                    &.down{
                        color: #F64242;
                    }
                }
            }
            &-head{
                padding: 0; 
                border-bottom: none;
                &-title{
                    padding: 0;
                    font-size: 10px;
                    b{
                        font-size: 10px; 
                    }
                }
            }
            &-body{
                padding: 0;
            }
        }
    }
    .managerList{
        .tokenInfo{
            font-weight: 700;
            font-size: 12px;
            // flex-direction: column;
            &>div{
                font-weight: 500;
                font-size: 16px;
                line-height: 20px
            }
        }
        .ant-card-head{
            min-height: 24px;
        }
        .ant-list-items{
            width: 100%;
            max-width: 100%;
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-gap: 12px;
            @media (max-width: 992px) {
                grid-template-columns: repeat(3, 1fr);
            }
            @media (max-width: 768px) {
                grid-template-columns: repeat(1, 1fr);
            }
            .ant-list-item{
                border: none;
                cursor: pointer;
                padding: 0;
            }
        }
        .btns{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            .ant-btn{
                svg{
                    color: var(--text-primary);
                    fill: var(--text-primary);
                }
                &:first-of-type{
                    padding: 0;
                }
                &.follow{
                    color: var(--green);
                    svg{
                        color: var(--green);
                        fill: var(--green);
                    }
                }
                &.unfollow{
                    color: var(--red);
                    svg{
                        color: var(--red);
                        fill: var(--red);
                    }
                }
            }
        }
    }
    .back{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 30px;
        &>div{
            &:last-of-type{
                text-transform:capitalize;
            }
            h2{
                margin-bottom: 0;
                font-weight: 700;
                font-size: 32px;
                color: var(--text-primary);
            }
        }
    }
    .subTitle{
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 20px;
        margin-top: 28px;
        color: var(--text-primary);
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 768px) {
            font-size: 18px;
        }
        &>div{
            &:last-of-type {
                color: var(--text-secondary);
                font-weight: 500;
                font-size: 14px;
                cursor: pointer;
            }
        }
    }
    .ai-box{
        margin: 30px 0;
        padding: 20px 0;
        background: url(../../assets/images/ai.jpg) no-repeat;
        background-size: 100%;
        width: 100%;
        h2{
            text-align: center;
            font-size: 32px;

        }
        p{
            text-align: center;
            font-size: 18px;
        }
        .search-input{
            width: 300px;
            margin: 0 auto;
            display: block;
        }
    }
    .enter-btn{
        text-align: right;
        margin: 20px 0;
    }
    .topics{
        margin: 20px 0;
        span{
            text-decoration: underline;
            font-size: 16px;
            color: var(--text-secondary);
            line-height: 1.2;
            margin:20px 10px;
            cursor: pointer;
        }
    }
}
