.aggregate{
    .title{
        font-weight: 700;
        font-size: 48px;
        color: var(--text-primary);
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        @media (max-width: 768px) {
            font-size: 24px;
        }
    }
    .swap-content{
        width: 640px;
        border: 1px solid var(--border-color);
        border-radius: 12px;
        margin: 0 auto;
        @media (max-width: 768px) {
            width: 100%;
        }
        .head{
            border-bottom: 1px solid var(--border-color);
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            svg{
                fill: var(--text-primary);
            }
            &>div{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px; 
                img{
                    margin-right: 5px;
                    width: 16px;
                    height: 16px;
                }
                &:last-of-type{
                    gap: 0;
                }
            }
        }
        .body{
            padding: 16px;

        }
    }
    .chart{
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 320px;
        grid-gap: 48px;
        @media (max-width: 768px) {
            grid-template-columns: repeat(1, 100%);
        }
        .swap-content{
            width: 320px;
            @media (max-width: 768px) {
                width: 100%;
            }
        } 
        .recharts-responsive-container{
            margin-top: 30px;
        }
        .price_box{
            display: flex;
            align-items: center;
            margin-top: 30px;
            .price{
                font-weight: 700;
                font-size: 32px;
                color: var(--text-primary);
                @media (max-width: 768px) {
                    font-size: 20px;
                }
            }
            span{
                font-weight: 500;
                font-size: 16px;
                @media (max-width: 768px) {
                    font-size: 14px;
                }
            }
        }
        .tokenInfo{
            font-weight: 700;
            font-size: 24px;
            color: var(--text-primary);
            display: flex;
            justify-content: space-between;
            .tokenAvator{
                width: 40px;
                height: 40px;
                &:last-of-type{
                    margin-left: -20px;
                }
            }
        }
        .ant-radio-group{
            display: flex;
            align-items: center; 
            @media (max-width: 768px) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                
            }
        } 
        .ant-radio-button-wrapper{
            display: flex;
            align-items: center;
            padding: 0 16px;
            text-align: center;
            justify-content: center;
            @media (max-width: 768px) {
                padding: 0 8px;
            }
        }
        .ant-radio-button-checked{
            background: #EDECFE;
            border-radius: 6px;
        }
        .input-content{
            &>div{
                .swith-btn{
                    display:  flex;
                }
            }
            .swith-btn{
                display: none;
                svg{
                    fill: var(--text-primary);
                }
            }
        }
    }
    .input-content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        #swap-currency-input,#swap-currency-output{
            background: var(--rink-card-background);
        }
        &>div{
            &:first-of-type{
                width: 100%;
                &>div{
                    &:first-of-type{
                        margin-bottom: 12px;
                    }
                }
            }
            .swith-btn{
                display:  none;
                @media (max-width: 768px) {
                    display: flex;
                }
                svg{
                    fill: var(--text-primary);
                }
            }
        }
        .swith-btn{
            text-align: right;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            svg{
                fill: var(--text-primary);
            }
            @media (max-width: 768px) {
                display: none;
            }
            .ant-btn{
                width: 32px;
                padding: 0;
                margin: 0 0 10px;
                text-align: right;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .btns{
        margin-top: 12px;
        .ant-btn{
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            // &[disabled]{
            //     color: var(--text-primary);
            // }
        }
        .ant-btn[disabled]{
            color: var(--text-secondary)!important;
            background-color: #D4D0F4;
            border: none;
        }
    }
}