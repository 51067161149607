@import '~antd/dist/antd.less';
.portfolio{
    .ant-progress-circle .ant-progress-text{
        font-size: 12px;
        color: var(--text-primary);
        font-weight: 700;

    }
    .tokenInfo{
        display: flex;
        align-items: center;
        justify-content: space-between;
        &>div{
            &:first-of-type{
                display: flex;
                align-items: center;
                font-weight: 700;
                font-size: 32px;
                color: var(--text-primary);
                span{
                    color: var(--text-secondary);
                    font-weight: 400;
                    font-size: 20px;
                    display: block;
                }
            }
        }
    }
    .title{
        font-weight: 400;
        font-size: 20px;
        color: var(--text-primary);
        margin: 20px 0;
    }
    .tokenAvator{
        width: 64px;
        height: 64px;
    }
    .price_box{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @media (max-width: 768px) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }
    .infoCard{
        margin-top: 20px;
        border: none;
        border-top: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
        border-radius: 0;
        .ant-card-head{
            border-bottom: none;
            a{
                text-decoration: none;
                line-height: 20px;
            }
        }
        .ant-card-body{
            padding: 0!important;
        }
        .ant-card-body{
            width: 100%;
            &>div{
                padding-bottom:24px!important;
                width: 100%;
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                @media (max-width: 768px) {
                    grid-template-columns: repeat(2, 1fr);
                }
                &>div{
                    &>div{
                        &:first-of-type{
                            color: var(--text-secondary);
                            font-weight: 400;
                            font-size: 14px;
                        }
                        &:last-of-type{
                            font-weight: 700;
                            font-size: 16px;
                            color: var(--text-primary);
                        }
                    }
                    &:last-of-type{
                        cursor: pointer;
                    }
                }
            }
            .title{
                padding: 0!important;
            }
        }
        &:last-of-type{
            margin-top: 18px;
        }
        p{
            color: #84818A;
            font-size: 12px;
            margin-bottom: 0;
        }
        b{
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 10px;
            display: block;
        }
        a{
            text-decoration: underline;
        }
    }
    .chartCard{
        background: #fff;
        height: 330px;
        padding: 20px 0!important; 
        .ant-card-body{
            padding: 0;
        }
        .price{
            font-weight: 700;
            font-size: 32px;
            color: var(--text-primary);
            margin-right: 15px;
            line-height: 50px;
            @media (max-width: 768px) {
                font-size: 30px;
            }
        }
        .up{
            color: #14B8A6;
            padding: 0 10px;
            height: 20px;
            font-size: 12px;
            line-height: 20px;
        }
        .down{
            color: #F64242;
            padding: 0 10px;
            height: 20px;
            font-size: 12px;
            line-height: 20px;
        }
        .card_footer{
            color: #84818A;
            font-size: 12px;
            text-align: left;
        }
        button{
            background: none;
            color: #84818A;
            font-size: 12px;
            border: 1px solid #EBEAED;
            cursor: pointer;
        }
        .ant-radio-group{
            display: flex;
            align-items: center; 
        }
        .ant-radio-button-wrapper{
            display: flex;
            align-items: center;
            padding: 0 16px;
        }
        .ant-radio-button-checked{
            background: #EDECFE;
            border-radius: 6px;
        }
    }
    .asset{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 12px;
        grid-row-gap: 24px;
        @media (max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
        }
        .ant-card{
            height: auto;
            .ant-card-body{
                display: flex;
                justify-content: space-between;
                padding: 16px;
                align-items: center;
                .ant-avatar{
                    margin-right: 6px;
                }
                &>div{
                    &:first-of-type{
                        h3{
                            margin-bottom: 0;
                            font-weight: 700;
                            font-size: 16px;
                            color: var(--text-primary);
                        }
                        p{
                            font-weight: 400;
                            font-size: 12px;
                            color: var(--text-secondary);
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }
    .left-content{
        border: 1px solid var(--border-color);
        border-radius: 12px;
        &>div{
            &:last-of-type{
                padding: 16px;
            }
        }
        .btns{
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 12px;
            .two{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 12px;
            }
            &.enable{
                grid-template-columns: repeat(1, 1fr);
            }
            .ant-btn{
                font-weight: 500;
                font-size: 16px;
                background: #EDECFE;
                color: var(--primary-color);
                border: none;
                &.ant-btn-primary{
                    background: var(--primary-color);
                    color: #fff;
                }
                svg{
                    margin-right: 10px;
                }
            }
        }
    }
    .network{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--border-color);
        padding: 16px;
        img{
            margin-right: 5px;
            width: 16px;
            height: 16px;
        }
    }
    .about{
        margin-top: 24px;
        border-top: 1px solid var(--border-color);
        border-bottom: 1px solid var(--border-color);
    }
    .hoverBtn{
        display: flex;
        justify-content: space-between;
        position: relative;
        .ant-btn{
            margin: 0 10px;
        }
        .top{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: rgba(0, 0, 0, 0.5);
            display: flex;
            justify-content: center;
            align-items: center;
            .ant-btn{
                display: block;
            }
        }
    }
}