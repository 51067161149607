.predictionRace{
    .title{
        font-weight: 700;
        font-size: 48px;
        color: var(--text-primary);
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        @media (max-width: 768px) {
            font-size: 24px;
        }
    }
    .recharts-responsive-container{
        height: 192px!important;
    }
    .subTitle{
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 20px;
        margin-top: 28px;
        color: var(--text-primary);
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 768px) {
          font-size: 18px;
        }
      &>div{
          &:last-of-type {
              color: var(--text-secondary);
              font-weight: 500;
              font-size: 14px;
              cursor: pointer;
          }
      }
    }
    .priceUnit{
        font-weight: 700;
        font-size: 16px;
        color: var(--text-primary);
        &>div{
            font-weight: 700;
            font-size: 12px;
            color: var(--text-primary); 
        }
        .tokenAvator{
            width: 16px;
            height: 16px;
            &:last-of-type{
                margin-left: -16px;
            }
        }
    }
    .swap-content{
        width: 640px;
        margin: 0 auto;
        @media (max-width: 768px) {
            width: 100%;
        }
        .head{
            border: 1px solid var(--border-color);
            border-radius: 12px;
            display: flex;
            align-items: center;
            padding: 16px;
            margin-bottom: 24px;
            svg{
                fill: var(--text-primary);
            }
            &>div{
                &:first-of-type{
                    margin-right: 12px;
                }
                img{
                    margin-right: 5px;
                    width: 16px;
                    height: 16px;
                }
                &:last-of-type{
                    gap: 0;
                }
            }
        }
        .body{
            padding: 16px;
            border-radius: 12px;
            border: 1px solid var(--border-color);
        }
    }
    .race{
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 320px;
        grid-gap: 48px;
        @media (max-width: 768px) {
            grid-template-columns: repeat(1, 100%);
        }
        .event{
            border-radius: 24px;
            overflow: hidden;
            img{
                width: 100%;
                height: 320px;
                object-fit: cover;
            }
        }
        .swap-content{
            width: 320px;
            @media (max-width: 768px) {
                width: 100%;
            }
        } 
        .recharts-responsive-container{
            margin-top: 30px;
        }
        .price_box{
            display: flex;
            align-items: center;
            margin-top: 30px;
            .price{
                font-weight: 700;
                font-size: 32px;
                color: var(--text-primary);
                @media (max-width: 768px) {
                    font-size: 20px;
                }
            }
            span{
                font-weight: 500;
                font-size: 16px;
                @media (max-width: 768px) {
                    font-size: 14px;
                }
            }
        }
        .tokenInfo{
            font-weight: 700;
            font-size: 24px;
            color: var(--text-primary);
            display: flex;
            justify-content: space-between;
            .tokenAvator{
                width: 40px;
                height: 40px;
                &:last-of-type{
                    margin-left: -20px;
                }
            }
        }
        .ant-radio-group{
            display: flex;
            align-items: center; 
        } 
        .ant-radio-button-wrapper{
            display: flex;
            align-items: center;
            padding: 0 16px;
            text-align: center;
            justify-content: center;
            @media (max-width: 768px) {
                padding: 0 8px;
            }
        }
        .ant-radio-button-checked{
            background: #EDECFE;
            border-radius: 6px;
        }
        .input-content{
            &>div{
                .swith-btn{
                    display:  flex;
                }
            }
            .swith-btn{
                display: none;
                svg{
                    fill: var(--text-primary);
                }
            }
        }
    }
    .input-content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        #swap-currency-input,#swap-currency-output{
            background: var(--rink-card-background);
        }
        &>div{
            &:first-of-type{
                width: 100%;
                &>div{
                    &:first-of-type{
                        margin-bottom: 12px;
                    }
                }
            }
            .swith-btn{
                display:  none;
                @media (max-width: 768px) {
                    display: flex;
                }
                svg{
                    fill: var(--text-primary);
                }
            }
        }
        .swith-btn{
            text-align: right;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            svg{
                fill: var(--text-primary);
            }
            @media (max-width: 768px) {
                display: none;
            }
            .ant-btn{
                width: 32px;
                padding: 0;
                margin: 0 0 10px;
                text-align: right;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
    .btns{
        margin-top: 12px;
        .ant-btn{
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            // &[disabled]{
            //     color: var(--text-primary);
            // }
        }
        .ant-btn[disabled]{
            color: var(--text-primary)!important;
        }
    }
    .infoCard{
        margin-top: 20px;
        border: none;
        border-bottom: 1px solid var(--border-color);
        border-radius: 0;
        .ant-card-head{
            border-bottom: none;
            a{
                text-decoration: none;
                line-height: 20px;
            }
        }
        .ant-card-body{
            padding: 0!important;
        }
        .ant-card-body{
            width: 100%;
            .token{
                grid-template-columns: repeat(2, 1fr) 180px;
            }
            &>div{
                padding-bottom:24px!important;
                width: 100%;
                display: grid;
                grid-template-columns: repeat(3, 1fr) 180px;
                gap: 10px;
                @media (max-width: 768px) {
                    grid-template-columns: repeat(2, 1fr);
                }
                &>div{
                    &>div{
                        &:first-of-type{
                            color: var(--text-secondary);
                            font-weight: 400;
                            font-size: 14px;
                        }
                        &:last-of-type{
                            font-weight: 700;
                            font-size: 16px;
                            color: var(--text-primary);
                        }
                        .ant-statistic{
                            display: inline-block;
                        }
                        .ant-statistic-content{
                            font-weight: 700;
                            font-size: 16px;
                            color: var(--text-primary);
                        }
                    }
                    &:last-of-type{
                        cursor: pointer;
                        width: 200px;
                    }
                }
            }
            .title{
                padding: 0!important;
            }
        }
        &:last-of-type{
            margin-top: 18px;
        }
        p{
            color: #84818A;
            font-size: 12px;
            margin-bottom: 0;
        }
        b{
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 10px;
            display: block;
        }
        a{
            text-decoration: underline;
        }
    }
    .history{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 24px;
        .author{
          display: flex;
          gap: 8px;
          align-items: center;
          .time{
              color: var(--text-secondary);
              font-weight: 400;
              font-size: 14px;
          }
        }
        .ant-tag{
            &.ONGOING,&.DRAW{
                color: var(--text-primary);
            }
            &.CANCELLED{
                color: var(--text-secondary);
            }
            &.SUCCESS{
                color: var(--green);
            }
            &.FAIL{
                color: var(--red);
            }
        }
      }
      .ant-comment{
        padding: 16px;
        border-radius: 12px;
        border: 1px solid var(--border-color);
        .ant-comment-inner{
            padding: 0;
        }
        .ant-comment-actions{
            display: inline-flex;
            justify-content: flex-start;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
            margin-top: 0;
            li{
                cursor: pointer;
                margin-right: 10px;
                &:first-of-type{
                    display: flex;
                }
            }
        }
        .ant-comment-content{
            position: relative;
        }
        .ant-comment-content-author{
            width: calc(100% - 100px);
        }
        .ant-comment-content-author-name{
            font-weight: 700;
            font-size: 16px;
            color: var(--text-primary);
            & > *{
                color: var(--text-primary);
            }
            span{
                font-weight: 400;
                font-size: 14px;
                color: var(--text-primary);
            }
        }
        .ant-comment-content-detail{
            padding: 5px 0;
            .content{
                display: flex;
                justify-content: space-between;
                a{
                    color: var(--primary-color);
                }
            }
            .more{
              color: var(--primary-color);
              font-size: 12px;
              float: right;
              line-height: 26px;
            }
            .extra{
                position: absolute;
                top: -5px;
                right: 0;
                cursor: pointer;
            }
        }
    
      }
    .options{
        .ant-space{
            width: 100%;
            gap: 12px!important;
        }
        &.ant-radio-group{
            width: 100%;
            .ant-space-item{
            border: 1px solid var(--border-color);
            border-radius: 8px;
            padding: 12px;
            }
            .ant-radio-wrapper{
                width: 100%;
                flex-direction: row-reverse;
                justify-content: space-between;
                display: flex;
                &>span{display: block;}
                &::after{
                    display: none;
                }
            }
        }
        .direction{
            width: 100%;
            display: flex;
            justify-content: space-between;
            gap: 10px;
            align-items: center;
        }
        .team{
            width: 100%;
            display: flex;
            justify-content: space-between;
            gap: 10px;
            align-items: center;
            &>div{
                &>div{
                    &:nth-of-type(1){
                        font-weight: 400;
                        font-size: 16px;
                        color: var(--text-secondary)
                    }
                    &:nth-of-type(2){
                        font-weight: 700;
                        font-size: 16px;
                        color: var(--text-secondary)
                    }
                    &:nth-of-type(3){
                        font-weight: 400;
                        font-size: 12px;
                        color: var(--text-secondary)
                    }
                }
            } 
        }
        .ant-radio-wrapper-checked{
            .team{
                &>div{
                    &>div{
                        &:nth-of-type(1){
                            color: var(--primary-color)
                        }
                        &:nth-of-type(2){
                            color: var(--primary-color)
                        }
                        &:nth-of-type(3){
                            color: var(--primary-color)
                        }
                    }
                } 
            }
        }
    }
}