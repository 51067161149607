.transactions{
    padding: 40px 0 0;
    margin-bottom: 40px;
    @media (max-width: 768px) {
        padding: 20px;
    }
    .chart{
        height: 553px;
        width: 100%;
        border: 1px solid var(--border-color);
        border-radius: 5px;
        margin: 0 0 75px;
        position: relative;
        @media (max-width: 768px) {
            height: 300px;
            margin: 0 0 30px 0;
        }
    }
    .balance{
        margin: 20px 50px;
        position: absolute;
        top: 0;
        left: 0;
        &>div{
            &:first-of-type{
                color: var(--text-secondary);
                font-weight: 700;
                font-size: 20px;
            }
            &:last-of-type{
                color: var(--text-primary);
                font-weight: 700;
                font-size: 30px;
            }
        }
    }
    .history{
        border: 1px solid var(--border-color);
        border-radius: 8px;
    }
    .ant-tabs > .ant-tabs-nav{
        margin: 25px 20px;
    }
    .ant-tabs-tab{
        font-weight: 700;
        font-size: 13px;
    }
    .ant-tabs-top > .ant-tabs-nav::before{
        display: none;
    }
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list{
        border: 1px solid var(--border-color);
        border-radius: 4px;
    }
    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active{
        background: var(--border-color);
    }
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
        color: var(--text-primary);
    }
    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab{
        border-radius: 0;
        border: none;
    }
    .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab{
        margin-left: 0;
    }
}