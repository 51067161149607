@import '~antd/dist/antd.less';
.my-portfolio{
    h2{
        font-weight: 700;
        font-size: 24px;
        color: var(--text-primary);
        margin-top: 20px;
    }
    p{
        color: var(--text-secondary);
    }
    .subTitle{
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 20px;
        margin-top: 28px;
        color: var(--text-primary);
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 768px) {
            font-size: 18px;
        }
        &>div{
            &:last-of-type {
                color: var(--text-secondary);
                font-weight: 500;
                font-size: 14px;
                cursor: pointer;
            }
        }
    }
    .myInfo{
        border: none;
        background: none;
        svg{
            fill:var(--text-primary);
        }
        .ant-card-body{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0;
            @media (max-width: 768px) {
                flex-direction: column;
                justify-content: flex-start;
            }
            &>div{
                padding: 0;
                &:first-of-type{
                    width: 60%;
                    display: flex;
                    align-items: center;
                    @media (max-width: 768px) {
                        border-right: none;
                        width: 100%;
                    }
                    button{
                        display: inline-block;
                    }
                    .avatar{
                        margin-right: 20px;
                        cursor: pointer;
                        @media (max-width: 768px) {
                            margin-right: 10px;
                        }
                    }
                    .ant-upload-select-picture-card{
                        border: 1px solid @border-color-base;
                        border-radius: 50%;
                        position: relative;
                        height: 65px;
                        width: 65px;  
                        margin: 0;
                        .ant-avatar{
                            height: 64px;
                            width: 64px;  
                        }
                        img{
                            border-radius: 50%;
                            height: 64px;
                            width: 64px;
                        }
                        .upload_btn{
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            opacity: 0.6;
                            .default{
                                position: relative;
                                .upload{
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    color: #5542F6;
                                    font-size: 30px;
                                }
                            }
                        }
                    }
    
                    .links{
                        font-weight: 700;
                        font-size: 24px;
                        @media (max-width: 768px) {
                            font-size: 18px;
                        }
                        button{
                            border: 1px solid @border-color-base;
                            border-radius: 4px;
                            padding: 5px;
                            display: inline-flex;
                            justify-content: center;
                            align-items: center;
                            margin-left: 10px;
                        }
                        &>div{
                            word-break: break-all;
                        }
                        .ant-tag{
                            cursor: pointer;
                            font-weight: 500;
                            font-size: 12px;
                        }
                    }
                    .ant-tag {
                        color: #000;
                        // display: flex;
                        // align-items: center;
                        margin-bottom: 5px;
                        svg{
                            height: 12px;
                            display: inline-block;
                        }
                        span{
                            display: inline-block;
                        }
                    }
                }
                &:last-of-type{
                    padding: 0;
                    width: 60%;
                    display: flex;
                    justify-content: flex-end;
                    @media (max-width: 768px) {
                        width: 100%;
                        padding: 0;
                        margin: 10px 0;
                        justify-content: flex-start;
                    }
                    .ant-btn{
                        &:first-of-type{
                            margin-right: 10px;
                        }
                        svg{
                            margin-right: 5px;
                        }
                        &.follow{
                            color: var(--green);
                            svg{
                                color: var(--green);
                                fill: var(--green);
                            }
                        }
                        &.unfollow{
                            color: var(--red);
                            svg{
                                color: var(--red);
                                fill: var(--red);
                            }
                        }
                    }
                }
            }
        }
    }
    .ant-tabs{
        margin-top: 30px;
        overflow: auto;
        & > .ant-tabs-nav{
            overflow: auto;
        }
        .ant-tabs-tab{
            font-weight: 400;
            color: var(--text-secondary);
            &.ant-tabs-tab-active{
                font-weight: 700;
            }
        }
        .ant-badge-count{
            margin-left: 5px;
            background: var(--badge-background);
            border-radius: 4px;
            color: var(--text-primary);
        }
    }
    .ant-tabs-top > .ant-tabs-nav::before{
        width: 100vw;
    }
    .ant-btn{
        display: inline-flex;
        align-items: center;
    }
    .media{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 24px;
        margin: 20px 0;
        @media (max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
        }
        &>div{
            border: 1px solid var(--border-color);
            border-radius: 12px;
            padding: 18px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        p{
            margin-bottom: 0;
            font-weight: 400;
            font-size: 14px;
            color: var(--text-secondary);
        }
        .ant-tag{
            border: none;
            background: none;
            font-weight: 700;
            font-size: 16px;
            color: var(--text-primary);
        }
        .ant-btn{
            font-weight: 500;
            font-size: 12px;
            color: var(--primary-color);
            svg{
                margin-right: 6px;
            }
        }
    }
    .balance{
        font-weight: 400;
        font-size: 20px;
        color: var(--text-primary);
        border: 1px solid var(--border-color);
        border-radius: 12px;
        &>div{
            padding: 16px;
            &:first-of-type{
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid var(--border-color);
                @media (max-width: 768px) {
                    flex-direction: column;
                    align-items: flex-start;
                }
                &>div{
                    &:first-of-type{
                        font-weight: 400;
                        font-size: 14px;
                        color: var(--text-secondary);
                    }
                    b{
                        font-weight: 700;
                        font-size: 20px;
                        color: var(--text-primary);
                        &.down,&.up{
                            font-weight: 500;
                            font-size: 14px;
                        }
                        &.up{
                            color: #14B8A6;
                        }
                        &.down{
                            color: #F64242;
                        }
                    }
                }
            }
            &:last-of-type{
                display: grid;
                grid-template-columns: repeat(5, 1fr);
                @media (max-width: 768px) {
                    grid-template-columns: repeat(2, 1fr);
                }
                p{
                    font-weight: 400;
                    font-size: 14px;
                    color: var(--text-secondary);
                    margin-bottom: 0;
                }
                b{
                    font-weight: 700;
                    font-size: 16px;
                    color: var(--text-primary)
                }
            }
        }
        .price{
            font-weight: 700;
            font-size: 32px;
            color: var(--text-primary);
            margin-right: 10px;
        }
        .update_time{
            font-weight: 400;
            font-size: 12px;
            color: var(--text-secondary);
            text-align: right;
        }
        .ant-btn{
            font-weight: 500;
            font-size: 12px;
            color: var(--text-primary);
            height: 32px;
            width: auto;
            padding: 2px 10px;
            & > .anticon + span{
                margin-left: 10px;
            }
        }
    }
    .marketsList,.portfolioList,.managerList{
        .tokenInfo{
            display: flex;
            font-weight: 700;
            font-size: 16px;
            color: var(--text-primary);
            align-items: center;
            span{
                font-weight: 400;
                font-size: 12px;
                color: var(--text-secondary);
            }
            .tokenAvator{
                height: 40px;
                width: 40px;
            }
        }
        .managerInfo{
            border-top: 1px solid var(--border-color);
            padding-top: 10px;
            margin-top: 10px;
            &>div{
                &:first-of-type{
                    font-weight: 500;
                    font-size: 12px;
                    color: var(--text-primary);
                }
                &:last-of-type{
                    font-weight: 400;
                    font-size: 12px;
                    color: var(--text-secondary);
                }
            }
        }
        .price{
            font-weight: 700;
            font-size: 20px;
            color: var(--text-primary);
            b{
                font-weight: 500;
                font-size: 12px;
            }
        }
        .ant-list-items{
            width: 100%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            align-items: self-start;
            grid-gap: 24px;
            @media (max-width: 992px) {
                grid-template-columns: repeat(2, 1fr);
            }
            @media (max-width: 768px) {
                grid-template-columns: repeat(1, 1fr);
            }
            .ant-list-item{
                border: none;
                cursor: pointer;
                padding: 0;
                height: 100%;
            }
        }
        .ant-card{
            border: 1px solid var(--border-color);
            width: 100%;
            border-radius: 12px;
            padding: 16px;
            height: 100%;
            &-head-title{
                img{ background: #ddd; border-radius: 50%;}
            }
            &-extra{
                padding: 0;
                font-size: 10px;
                display: flex;
                align-items: center;
                cursor: pointer;
                svg{
                    font-size: 15px;
                }
                .anticon-more{
                    margin-left: 10px;
                }
                b{
                    &.up{
                        color: #14B8A6;
                    }
                    &.down{
                        color: #F64242;
                    }
                }
            }
            &-head{
                padding: 0; 
                border-bottom: none;
                &-title{
                    padding: 0;
                    font-size: 10px;
                    b{
                        font-size: 10px; 
                    }
                }
            }
            &-body{
                padding: 0;
            }
        }
        .tokens{
            border-top: 1px solid @border-color-base;
            margin-top: 12px;
            padding-top: 12px;
            &>div{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 5px 0;
                .ant-avatar{
                    margin-right: 5px;
                }
            }
        }
    }
    .managerList{
        .tokenInfo{
            font-weight: 700;
            font-size: 12px;
            flex-direction: column;
            &>div{
                margin: 10px 0;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px
            }
        }
        .btns{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            .ant-btn{
                svg{
                    fill: var(--text-primary);
                }
                &:first-of-type{
                    padding: 0;
                }
                &.follow{
                    color: #14B8A6;
                    svg{ fill: #14B8A6;}
                }
                &.unfollow{
                    color: #F43F5E;
                    svg{ fill: #F43F5E;}
                }
            }
        }
    }
    .proposalsList{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 24px;
        @media (max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
        }
        .item{
            border: 1px solid var(--border-color);
            background: var(--background); 
            border-radius: 12px;
            padding: 16px;
            cursor: pointer;
            width: 100%;
            overflow: hidden;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
    
            .head {
                height: 16px;
                display: flex;
                justify-content: space-between;
                margin-bottom: 12px;
                .chain{
                    border-bottom-left-radius: 12px;
                    color: #4274F6;
                    font-size: 12px;
                    line-height: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
                .id{
                    border-bottom-right-radius: 12px;
                    color: #71717A;
                    font-size: 12px;
                    line-height: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
    
            .category {
                font-size: 12px;
                line-height: 16px;
                margin-bottom: 4px;
            }
    
    
            h2{
                font-weight: 500;
                font-size: 20px;
                line-height: 20px;
                color: var(--text-primary);
                height: 20px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            p{
                font-size: 14px;
                color: #000;
                height: 20px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .created, .ended{
                color: #84818A;
                font-size: 12px;
                margin-bottom: 0;
            }
            .footer{
                display: flex;
                justify-content: space-between;
                line-height: 20px;
                .tag{
                    padding: 0px 4px;
                    margin-right: 10px;
                    font-weight: 600;
                    font-size: 12px;
                    height: 20px;
                    text-align: center;
                    line-height: 20px;
                    border-radius: 4px;
                    color: #fff;
                    &.active{
                        background: #14B8A6; 
                    }
                    &.soon{
                        background: #5542F6;
                    }
                    &.closed,&.canceled{
                        background: #A1A1AA;
                    }
                }
            }
        }
    }
    .transaction-list{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 24px;
        @media (max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
        }
        .item{
            border: 1px solid var(--border-color);
            border-radius: 12px;
            padding: 16px;
            &>div{
                &:last-of-type{
                    display: flex;
                    justify-content: space-between;
                }
            }
        }
        svg{
            fill: var(--text-primary);
        }
        .swap{
            display: grid;
            grid-template-columns: 1fr 30px 1fr;
            width: 60%;
            align-items: center;
            justify-content: center;
            &>div{
                &:nth-of-type(2){
                    text-align: center;
                }
                font-weight: 700;
                font-size: 14px;
                color: var(--text-primary);
            }
            img{
                width: 24px;
                height: 24px;
                border-radius: 12px;
                margin-right: 5px;
            }
        }
    }
}
.create-menu{
    width: 192px;
    border: 1px solid var(--border-color);
    box-shadow: 0px 8px 10px -6px rgba(0, 0, 0, 0.1), 0px 20px 25px -5px rgba(0, 0, 0, 0.1)!important;
    border-radius: 12px!important;
    .ant-dropdown-menu-item{
        padding: 16px 20px;
        font-weight: 500;
        font-size: 14px;
        color: var(--text-primary);
        &:not(:first-of-type){
            border-top: 1px solid var(--border-color);
        }
        .ant-dropdown-menu-title-content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            svg{
                width: 15px;
                fill: var(--text-primary);
            }
        }
    }
}