.predictDetail{
    padding: 40px 0 0;
    margin-bottom: 40px;
    display: grid;
    grid-template-columns: auto 348px 25px;
    @media (max-width: 768px) {
        grid-template-columns: 100%;
        padding: 0!important;
    }
    &>div{
        &:nth-of-type(1){
            @media (max-width: 768px) {
                padding:0 20px;
            }
        }
        &:nth-of-type(2){
            border-radius: 15px;
            position: relative;
            padding-left: 40px;
            @media (max-width: 768px) {
                padding:0 20px;
            }
        }
    }
    .head{
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        svg{
            cursor: pointer;
        }
        // &>div{
        //     &:last-of-type{
        //         svg{
        //             margin-left: 25px;
        //         }
        //     }
        // }
    }
   .subTitle{
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 20px;
        margin-top: 28px;
        color: var(--text-primary);
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 768px) {
            font-size: 18px;
        }
    }
    .precent{
        height: 50px;
        line-height: 50px;
        width: 100%;
        border-radius: 20px;
        overflow: hidden;
        color:#fff;
        font-weight: 700;
        font-size: 20px;
        display: grid;
        margin-top: 30px;
        position: relative;
        background: var(--border-color);
        .yes{
            background: rgba(20, 184, 166, 0.4);
        }
        .no{
            background: rgba(85, 66, 246, 0.4);
        }
        .text{
            position: absolute;
            width: 100%;
            top: 0;
            left: 0;
            display: flex;
            justify-content: space-between;
            padding: 0 10px;
            &>div{
                &:first-of-type{
                    color: var(--green);
                }
                &:last-of-type{
                    color: var(--primary-color);
                }
            }
        }
    }
    .chart{
        height: 553px;
        width: 100%;
        border: 1px solid var(--border-color);
        border-radius: 5px;
        margin: 75px 0;
        @media (max-width: 768px) {
            margin: 20px 0;
        }
        .ant-radio-group{
            display: flex;
            gap: 10px;
            margin-top: 20px;
            margin-right: 20px;
        }
        .ant-radio-button-wrapper{
            border: 1px solid var(--border-color)!important;
            border-radius: 30px;
            height: 25px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .history{
        border: 1px solid var(--border-color);
        border-radius: 10px;
        &>div{
            display: flex;
            justify-content: flex-start;
            gap: 20px;
            padding: 35px 20px;
            &:not(:last-of-type){
                border-bottom: 1px solid var(--border-color);
            }
        }
        h4{
            font-weight: 700;
            font-size: 15px;
            color: var(--text-primary);
        }
        p{
            font-weight: 400;
            font-size: 14px;
            color: var(--text-secondary);
        }
    }
    .ant-card{
        // .cardHead{
        //     display: grid;
        //     grid-template-columns: 88px 1fr;
        //     align-items: center;
        //     gap: 30px;
        //     @media (max-width: 768px) {
        //         grid-template-columns: 100%;
        //     }
        // }
        h2{
            // white-space: nowrap;
            // text-overflow: ellipsis;
            // overflow: hidden;
            font-weight: 700;
            font-size: 30px;
            color: var(--text-primary);
            margin-bottom: 0;
            @media (max-width: 768px) {
                font-size: 24px;
            }
        }
        p{
            font-weight: 400;
            font-size: 14px;
            color: var(--text-secondary);
            margin-top: 30px;
            span{
                margin-right: 15px;
            }
        }
    }
    .yesNoChart{
        height: 120px;
        width: 100%;
        .ant-radio-group{
            display: flex;
            gap: 10px;
        }
        .ant-radio-button-wrapper{
            border: 1px solid var(--border-color)!important;
            border-radius: 30px;
            height: 25px;
            width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .title{
        color: #fff;
        height: 48px;
        text-align: center;
        padding-top: 35px;
        border-radius: 15px 15px 0 0; 
        &.Yes{
            background: var(--green);
        }
        &.No{
            background: var(--primary-color);
        }
        &.Liquidity{
            background: #6041DB;;
        }

    }
    .content{
        padding: 64px 24px 24px;
        border: 1px solid var(--border-color);
        border-radius:0 0 15px 15px;
        position: relative;
    }
    .liquidityBtn{
        position: absolute;
        top: -25px;
        right: -25px;
        z-index: 10;
        width: 50px;
        height: 50px;
        border-radius: 50px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        cursor: pointer;
    }
    .liquidity{
        position: absolute;
        top: -25px;
        left: calc(50% + 12.5px);
        transform: translateX(-50%);
        height: 50px;
        border-radius: 50px;
        padding: 4px;
        display: flex;
        justify-content: space-between;
        background: #fff;
        &>div{
            background: #CCC2FF;
            border-radius: 50px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #6041DB;
            font-weight: 700;
            font-size: 15px;
            &:first-of-type{
                width: 120px;
            }
            &:last-of-type{
                width: 42px;
                height: 42px;
                cursor: pointer;
            }
        }
    }
    .ant-segmented:not(.ant-segmented-disabled):hover, .ant-segmented:not(.ant-segmented-disabled):focus{
        background: var(--background);
        box-shadow: none;
    }
    .yesNoSegmented{
        position: absolute;
        top: -25px;
        left: calc(50% + 12.5px);
        transform: translateX(-50%);
        height: 50px;
        border-radius: 50px;
        line-height: 50px;
        background: #fff;
        border: 4px solid #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        .ant-segmented-item-label{
            line-height: 42px;
            padding: 0 10px;
            font-weight: 700;
            font-size: 13px;
            color: #000;
        }
        .ant-segmented-thumb{
            border-radius: 50px!important;
        }
        .ant-segmented-item{
            border-radius: 50px!important;
            box-shadow: none;
        }
        &.Yes{
            .ant-segmented-item-selected{
                background: #E0F8D4;
                color: var(--green);
                .ant-segmented-item-label{
                    color: var(--green);
                }
            }
            .ant-segmented-thumb{
                background: #E0F8D4;
            }
        }
        &.No{
            .ant-segmented-item-selected{
                background: #C2D5F1;
                color: var(--primary-color);
                .ant-segmented-item-label{
                    color: var(--primary-color);
                }
            }
            .ant-segmented-thumb{
                background: #C2D5F1;
            }
        }
    }
    .buySellTab{
        border: 1px solid var(--border-color);
        border-radius: 7px;
    }
    .buySellSegmented{
        height: 36px;
        border: 1px solid var(--border-color);
        box-sizing: content-box;
        border-radius: 7px;
        .ant-segmented-item-label{
            font-weight: 700;
            font-size: 13px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .ant-segmented-thumb{
            border-radius: 7px;
        }
        .ant-segmented-item{
            border-radius: 7px;
            box-shadow: none;
        }
        &.Yes{
            .ant-segmented-item-selected{
                background: var(--green);
                color:#fff;
            }
            .ant-segmented-thumb{
                background: var(--green);
            }
        }
        &.No{
            .ant-segmented-item-selected{
                background: var(--primary-color);
                color:#fff;
            }
            .ant-segmented-thumb{
                background: var(--primary-color);
            }
        }
        &.Liquidity{
            .ant-segmented-item-selected{
                background: #6041DB;
                color:#fff;
            }
            .ant-segmented-thumb{
                background: #6041DB;
            }
        }
    }
    .shares{
        border: 1px solid var(--border-color);
        border-radius: 8px;
        margin: 17px 0;
        padding: 8px;
        &>div{
            display: flex;
            justify-content: space-between;
            font-weight: 400;
            font-size: 12px;
            &:first-of-type{
                color: var(--text-primary);
            }
            &:last-of-type{
                color: var(--text-secondary);
            }
        }
    }
    .balance{
        text-align: right;
        margin: 0;
        font-weight: 400;
        font-size: 12px;
        color: var(--text-primary);
    }
    .cost{
        margin: 17px 0;
        &>div{
            display: flex;
            justify-content: space-between;
            font-weight: 400;
            font-size: 12px;
            &>div{
                &:first-of-type{
                    color: var(--text-secondary);
                }
                &:last-of-type{
                    color: var(--text-primary);
                }
            } 
        }
    }
    .amountInput{
        border: 1px solid var(--border-color);
        border-radius: 8px;
        padding: 8px;
        margin-bottom: 0;
        .ant-input{
            border: none;
            text-align: right;
            &:hover{
                outline: 0!important;
                box-shadow: none!important;
                border: none;
            }
        }
        .ant-form-item-label{
            text-align: left;
        }
        .ant-form-item-explain{
            position: relative;
            left: -100%;
            width: 200%;
        }
        .ant-input[disabled]{
            background: var(--background);
        }
    }
    .info{
        margin-bottom: 17px;
        border: 1px solid var(--border-color);
        border-radius: 8px;
        padding: 8px;
        &>div{
            display: flex;
            justify-content: space-between;
            font-weight: 400;
            font-size: 12px;
            color: var(--text-secondary);
        }
    }
    .ant-btn{
        height: 44px;
        border: none;
        font-weight: 700;
        font-size: 13px;
        &.Yes{
            background: var(--green);
            &[disabled]{
                color: #fff;
                &:hover{
                    color: #fff;
                    background: var(--green);
                }
            }
        }
        &.No{
            background: var(--primary-color);
            &[disabled]{
                color: #fff;
                &:hover{
                    color: #fff;
                    background: var(--primary-color);
                }
            }
        }
        &.Liquidity{
            background: #6041DB;;
        }
        &.ended{
            background-color: var(--border-color);
            color: var(--text-primary);
        }
    }
}