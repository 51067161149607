.stsBuy{
    .input-item{
        box-sizing: border-box;
        border-radius: 4px;
        padding: 12px;
        width: 100%;
        margin-bottom: 12px;
        background: var(--rink-card-background);
        border-radius: 8px;
        // display: flex;
        // justify-content: space-between;
        &>div{
            &:first-of-type{
                width: auto;
            }
        }
    }
    .ant-select{
        min-width: 120px;
    }
    .btns{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 12px;
        .ant-btn{
            font-weight: 500;
            font-size: 16px;
            background: #EDECFE;
            color: var(--primary-color);
            border: none;
            &.ant-btn-primary{
                background: var(--primary-color);
                color: #fff;
            }
            svg{
                margin-right: 10px;
            }
        }
    }
}