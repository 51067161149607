.moreRace{
    .subTitle{
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 20px;
        margin-top: 28px;
        color: var(--text-primary);
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width: 768px) {
          font-size: 18px;
        }
    }
    .raceList{
        .recharts-responsive-container{
            height: 50px!important;
            margin-bottom: 25px;
        }
        .tokenImages{
            text-align: center;
            .tokenAvator{
                &:first-of-type{
                    margin-right: -8px;
                }
                &:last-of-type{
                    margin-left: -8px;
                }
            }
            img{
                width: 64px;
                height: 64px;
            }
        }
      .symbol{
          text-align: center;
          font-weight: 700;
          font-size: 24px;
          color: var(--text-primary);
      }
      .ant-card-head-title{
          font-size: 12px;
          color: var(--text-secondary);
      }
      .ant-card-extra,.ant-statistic-content{
          font-size: 12px;
          color: var(--text-primary);
      }
      .ant-card{
          height: 388px;
          &.price{
            .ant-card-body{
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                height: calc(100% - 48px);
            }
        }
      }
      .ant-list-item{
          border-radius: 12px;
          background-size: cover!important;
          background-position: center!important;
      }
      .joined{
        text-align: center;
        .tag{
            padding: 4px 8px;
            margin: 12px auto;
            border-radius: 20px;
            background: var( --background-secondary);
            display: inline-flex;
            align-items: center;
            justify-content: center;
            svg{
                margin-right: 5px;
            }
        }
    }
      .event{
          position: relative;
          background-size: 100%, cover!important;
          background-position: center, center;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.35) 19.79%, rgba(0, 0, 0, 0.35) 59.37%, rgba(0, 0, 0, 0.75) 100%),
              !important;
          .ant-card-head-title,.ant-card-extra,.ant-statistic-content{
              font-size: 12px;
              color: #fff;
          }
          .ant-card-body{
              position: absolute;
              bottom: 16px;
              left: 0;
              width: 100%;
          }
          .title{
              font-weight: 700;
              font-size: 24px;
              color: #fff;
              text-align: center;
          }
      }
      .ant-btn-primary{
          display: block;
          text-align: center;
          margin: 0 auto;
      }
      .sofi{
          text-align: center;
          margin: 0 auto;
          &>div{
              background: var(--rink-card-background);
              border-radius: 20px;  
              width: auto;
              display: inline-flex;
              align-items: center;
              width: auto;
              align-items: center;
              padding: 4px;
              font-weight: 700;
              font-size: 16px;
              color: var(--text-primary);
              svg{
                  margin-right: 5px;
              }
          }
      }
      .tokenInfo{
          display: flex;
          font-weight: 700;
          font-size: 16px;
          color: var(--text-primary);
          align-items: center;
          &>div{
              max-width: 118px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
          }
          .ant-avatar{
              margin-right: 10px;
          }
          img{
            width: 40px;
            height: 40px;
          }
      }
      .managerInfo{
          border-top: 1px solid var(--border-color);
          padding-top: 10px;
          margin-top: 10px;
          &>div{
              &:first-of-type{
                  font-weight: 500;
                  font-size: 12px;
                  color: var(--text-primary);
              }
              &:last-of-type{
                  font-weight: 400;
                  font-size: 12px;
                  color: var(--text-secondary);
              }
          }
      }
      .price{
          font-weight: 700;
          font-size: 20px;
          color: var(--text-primary);
          b{
              font-weight: 500;
              font-size: 12px;
          }
      }
      .ant-list-items{
          width: 100%;
          max-width: 100%;
          display: grid;
          grid-template-columns: repeat(3, minmax(220px, 1fr));
          grid-gap: 24px;
          @media (max-width: 992px) {
              grid-template-columns: repeat(2, 1fr);
          }
          @media (max-width: 768px) {
              grid-template-columns: repeat(1, 1fr);
          }
          .ant-list-item{
              border: none;
              cursor: pointer;
              padding: 0;
          }
      }
      .ant-card{
          border: 1px solid var(--border-color);
          width: 100%;
          border-radius: 12px;
          padding: 16px;
          &-head-title{
              img{ background: #ddd; border-radius: 50%;}
          }
          &-extra{
              padding: 0;
              font-size: 10px;
              display: flex;
              align-items: center;
              cursor: pointer;
              svg{
                  font-size: 15px;
              }
              .anticon-more{
                  margin-left: 10px;
              }
              b{
                  &.up{
                      color: #14B8A6;
                  }
                  &.down{
                      color: #F64242;
                  }
              }
          }
          &-head{
              padding: 0; 
              border-bottom: none;
              &-title{
                  padding: 0;
                  font-size: 10px;
                  b{
                      font-size: 10px; 
                  }
              }
          }
          &-body{
              padding: 0;
          }
    }
}
}