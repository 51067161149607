@import 'themes/light.theme.less';
.create-portfolio{
    input[type='text'],
    input[type='number'],
    input {
        font-size: 14px;
    }
    .text{
        font-weight: 400;
        font-size: 12px;
        color: var(--text-secondary);
    }
    h2{
        font-weight: 400;
        font-size: 20px;
        color: var(--text-primary);
        margin: 40px 0;
    }
    h3{
        font-weight: 600;
        font-size: 20px;
    }
    p{
        font-size: 16px;
        margin-bottom: 40px;
        font-weight: normal;

    }
    .form .ant-form-item-label > label{
        color: var(--text-primary);
    }
    .ant-input-group-addon{
        svg{
            fill: var(--text-primary);
        }
    }
    .tokens{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 24px;
        @media (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }
        .ant-card{
            .ant-card-head{
                padding: 0 16px;
            }
            .ant-card-body{
                padding: 16px;
            }
        }
    }
    .ant-slider{
        margin: 0;
        &-track{
            background: var(--primary-color);
        }
        &-handle{
            border: 0.5px solid #EBEAED;
            box-shadow: 1px 1px 5px var(--primary-color); 
        }
        &-disabled{
            .ant-slider-track{
                background-color: var(--primary-color)!important;
            }
            .ant-slider-handle{
                border: 0.5px solid #EBEAED!important;
            }
        }
    }
    // .ant-result{
        .ant-btn{
            background: var(--rink-card-background);
            color: var(--text-primary);
            border-radius: 8px;
            height: 48px;
            font-weight: 500;
            font-size: 16px;
            border: none;
            &:hover,&:focus{
                background: var(--rink-card-background);
                color: var(--text-primary);
            }
        }
        .ant-btn-primary{
            background: var(--primary-color);
            color: #fff;
            &:hover,&:focus{
                background: var(--primary-color);
                color: #fff;
            }
        }
    // }
    .percentInput{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .ant-form-item{
             margin-bottom: 0;
        }
        .ant-form-item-explain{
            height: 24px;
            text-align: left;
        }
        .ant-form-item-explain-error{
            display: block;
        }
    }
    .ant-progress-circle .ant-progress-text{
        font-size: 10px;
    }
    .ant-steps{
        margin-bottom: 30px!important;
        .ant-steps-item-container{
            display: flex;
            align-items: center;
            font-weight: 700;
            font-size: 20px;
        }
    }
    .ant-upload.ant-upload-select-picture-card{
        border: 1px solid var(--border-color);
        border-radius: 50%;
        width: 64px;
        height: 64px;
        img{
            border-radius: 50%;
            height: 62px;
        }
    }
    .upload-btn{
        display: flex;
        align-items: center;
    }
    .tokenList{
        list-style: none;
        padding: 0;
        display: grid;
        grid-gap: 12px;
        grid-template-columns: repeat(4, 1fr);
        @media (max-width: 768px) {
            grid-template-columns: repeat(2, 1fr);
        }
        &>li{
            border: 1px solid var(--border-color);
            border-radius: 12px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            &:not(:last-of-type){
                padding-right: 30px;
            }
        }
    }
    .tokenInfo{
        display: flex;
        align-items: center;
        &>div{
            max-width: 118px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            &>div{
                &:first-of-type{
                    font-weight: 700;
                    font-size: 32px;
                    color: var(--text-primary);
                    line-height: 1;
                }
                display: block;
                font-weight: 400;
                font-size: 20px;
                color: var(--text-secondary);
            }
        }
        .tokenAvator{
            width: 64px;
            height: 64px;
        }
        .ant-avatar{
            margin-right: 10px;
        } 
    }
    .message{
        font-size: 18px;
        color: var(--text-secondary);
        @media (max-width: 768px) {
            font-size: 16px;
        }
    }
}
.tokenAvator{
    width: 20px;
    height: 20px;
    background: #ddd;
    border-radius: 50%;
    margin-right: 10px;
}